/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Cookies from 'universal-cookie';

import Header from "./header"
import Footer from "./footer"
import DonationBar from "./donation-bar"

const Layout = ({ children }) => {
  const cookies = new Cookies(),
        donationBarStateCookieVal = cookies.get('donation-bar-state'),
        [showDonationBar, setShowDonationBar] = useState(false);

  useEffect(() => {
    if (donationBarStateCookieVal !== 'closed') {
      setShowDonationBar(true);
    }
  }, [donationBarStateCookieVal]);

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <div>
        <main>{children}</main>
      </div>
      <Footer />
      {/*showDonationBar && <DonationBar />*/}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
