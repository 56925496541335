import * as React from "react"
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
  MenuIcon,
  XIcon,
} from '@heroicons/react/outline'
import { ChevronDownIcon } from '@heroicons/react/solid'
import logoBlue from '../images/logo-blue.webp'
import logoWhite from '../images/logo-white.webp'

// TODO: fix broken mobile header nav
const mobileHeaderNavPrimary = [
  {
    name: 'A Letter From John',
    href: '/letter-from-john',
  },
  {
    name: 'Meet John',
    href: '/meet-john',
  },
  {
    name: 'Issues',
    href: '/issues',
  },
  {
    name: 'Media - Press',
    href: '/media/press',
  },
  {
    name: 'Media - Photos',
    href: '/media/photos',
  },
  {
    name: 'Media - Endorsements',
    href: '/media/endorsements',
  },
]
const media = [
  {
    name: 'Press',
    description: '',
    href: '/media/press',
  },
  {
    name: 'Photos',
    description: '',
    href: '/media/photos',
  },
  {
    name: 'Endorsements',
    description: '',
    href: '/media/endorsements',
  }
]
const social = [
  /*{
    name: 'Facebook',
    href: 'https://www.facebook.com/JohnForPVD',
    icon: (props) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path
          fillRule="evenodd"
          d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },*/
  {
    name: 'Instagram',
    href: 'https://www.instagram.com/johngpvd',
    icon: (props) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path
          fillRule="evenodd"
          d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
  {
    name: 'Twitter',
    href: 'https://www.twitter.com/johngpvd',
    icon: (props) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
      </svg>
    ),
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Header() {
  return (
    <Popover className="sticky top-0 bg-blue-bgDark z-10">
      <div className="bg-yellow-bgAlert">
        <div className="max-w-7xl mx-auto px-4 xl:px-0 pt-5 pb-5">
          <div className="flex flex-wrap items-center gap-x-4 gap-y-2">
            <p className="text-xl leading-6 text-gray-900 mb-0">
              <strong>Vote for John Goncalves</strong> in the primary election TODAY (Tues., 9/5/2023)!  Polls will be open from 7 a.m.-8 p.m.
            </p>
            <a
              href="https://vote.sos.ri.gov/Home/PollingPlaces?ActiveFlag=2"
              className="flex-none rounded-full bg-blue-buttonLight hover:bg-blue-700 px-3.5 py-1 text-xl font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
              target="_blank"
            >
              Find your polling place
            </a>
          </div>
        </div>
      </div>
      <div className="max-w-7xl mx-auto px-4 xl:px-0">
        <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <a href="/">
              <span className="sr-only">John Goncalves for Congress (D) (RI-01) - Putting Our Community First</span>
              <img
                className="logo"
                src={logoBlue}
                alt="logo"
              />
            </a>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none">
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group as="nav" className="hidden md:flex space-x-10">
            <a href="/letter-from-john" className="text-xl font-medium text-white hover:underline">
              A Letter From John
            </a>
            <a href="/meet-john" className="text-xl font-medium text-white hover:underline">
              Meet John
            </a>
            <a href="/issues" className="text-xl font-medium text-white hover:underline">
              Issues
            </a>
            <Popover className="relative">
            {({ open }) => (
              <>
                <Popover.Button
                  className={classNames(
                    open ? 'text-white' : 'text-white',
                    'group rounded-md inline-flex items-center text-xl font-medium hover:underline focus:outline-none'
                  )}
                >
                  <span>Media</span>
                  <ChevronDownIcon
                    className={classNames(
                      open ? 'text-white' : 'text-white',
                      'ml-2 h-5 w-5 group-hover:underline'
                    )}
                    aria-hidden="true"
                  />
                </Popover.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="bg-white absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-xs sm:px-0">
                    <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                      <div className="relative grid gap-6 px-5 py-6 sm:gap-8 sm:p-8">
                        {media.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className="-m-3 p-3 block rounded-md hover:bg-gray-50"
                          >
                            <p className="text-lg font-medium text-gray-900">{item.name}</p>
                            <p className="text-sm text-gray-500">{item.description}</p>
                          </a>
                        ))}
                      </div>
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )}
            </Popover>
          </Popover.Group>

          <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
            <div className="hidden xl:flex space-x-6">
              {social.map((item) => (
                <a key={item.name} href={item.href} className="text-white hover:text-gray-400" target="_blank">
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-12 w-12" aria-hidden="true" />
                </a>
              ))}
            </div>
            <a
              href="/volunteer"
              className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-lg font-medium text-white bg-blue-buttonLight hover:bg-blue-700"
            >
              Volunteer
            </a>
            <a
              href="https://secure.actblue.com/donate/john-goncalves-website-donate?refcode=website-header-nav-desktop-tablet"
              className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-lg font-medium text-white bg-orange-button hover:bg-red-700"
            >
              Donate
            </a>
          </div>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel focus className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 divide-y-2 divide-gray-50 bg-white">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <div>
                  <img
                    className="h-8 w-auto"
                    src={logoWhite}
                    alt="John Goncalves for Congress (D) (RI-01) - Putting Our Community First"
                  />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid gap-y-8">
                  {mobileHeaderNavPrimary.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                    >
                      <span className="ml-3 text-base font-medium text-gray-900">{item.name}</span>
                    </a>
                  ))}
                </nav>
              </div>
            </div>
            <div className="py-6 px-5 space-y-6">
              <a
                href="/volunteer"
                className="whitespace-nowrap flex px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-buttonLight hover:bg-blue-700"
              >
                Volunteer
              </a>
              <a
                href="https://secure.actblue.com/donate/john-goncalves-website-donate?refcode=website-header-nav-phone"
                className="whitespace-nowrap flex px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-orange-button hover:bg-red-700"
              >
                Donate
              </a>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}
