import * as React from 'react'
import { navigate } from 'gatsby'

const onFormSubmit = e => {
  e.preventDefault();

  let footerNgpVanEmailSignupForm = document.querySelector('.footer-ngp-van-email-signup-form'),
      footerNgpVanEmailSignupSuccess = document.querySelector('.footer-ngp-van-email-signup-success'),
      footerNgpVanEmailSignupAlreadySubscribed = document.querySelector('.footer-ngp-van-email-signup-already-subscribed'),
      footerNgpVanEmailSignupError = document.querySelector('.footer-ngp-van-email-signup-error');

  const XHR = new XMLHttpRequest();

  // Bind the FormData object and the form element
  const FD = new FormData(e.target);

  // Define what happens on successful data submission
  XHR.addEventListener('load', (event) => {
    fadeOut(footerNgpVanEmailSignupForm);
    fadeIn(footerNgpVanEmailSignupSuccess);

    navigate('https://secure.actblue.com/donate/john-goncalves-website-signup?refcode=email_signup_website_footer');
  });

  // Define what happens in case of error
  XHR.addEventListener('error', (event) => {
    fadeIn(footerNgpVanEmailSignupError);
  });

  // Set up our request
  XHR.open('POST', 'https://actions.ngpvan.com/v2/Forms/MO5Re5Vt9kO1YtfIFtjjBQ2');

  // Add the required HTTP header for form data POST requests
  XHR.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

  // The data sent is what the user provided in the form
  XHR.send(new URLSearchParams(FD));
}

// fade out
const fadeOut = function fadeOut(el){
  el.style.opacity = 1;

  (function fade() {
    if ((el.style.opacity -= .1) < 0) {
      el.style.display = "none";
    } else {
      requestAnimationFrame(fade);
    }
  })();
}

// fade in
const fadeIn = function fadeIn(el, display){
  el.style.opacity = 0;
  el.style.display = display || "block";

  (function fade() {
    var val = parseFloat(el.style.opacity);
    var proceed = ((val += 0.1) > 1) ? false : true;
    
    if (proceed) {
      el.style.opacity = val;
      requestAnimationFrame(fade);
    }
  })();
}

/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
const navigation = {
    meetJohn: [
      { name: 'A Letter From John', href: '/letter-from-john' },
      { name: 'Meet John', href: '/meet-john' },
      { name: 'Issues', href: '/issues' },
    ],
    media: [
      { name: 'Press', href: '/media/press' },
      { name: 'Photos', href: '/media/photos' },
      { name: 'Endorsements', href: '/media/endorsements' },
    ],
    getInvolved: [
      { name: 'Donate', href: 'https://secure.actblue.com/donate/john-goncalves-website-donate?refcode=website-footer-nav', className: 'flex justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-lg font-medium text-white bg-orange-button hover:bg-red-700' },
      { name: 'Volunteer', href: '/volunteer', className: 'flex justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-lg font-medium text-white bg-blue-buttonLight hover:bg-blue-700' },
    ],
    contact: [
      { name: 'General contact: info@johngforcongress.com', href: 'mailto:info@johngforcongress.com', className: 'text-base text-gray-300 hover:text-white' },
      { name: 'Press inquiries: press@johngforcongress.com', href: 'mailto:press@johngforcongress.com', className: 'text-base text-gray-300 hover:text-white' }
    ],
    social: [
      {
        name: 'Facebook',
        href: 'https://www.facebook.com/JohnForPVD',
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
      {
        name: 'Instagram',
        href: 'https://www.instagram.com/johngpvd',
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
      {
        name: 'Twitter',
        href: 'https://www.twitter.com/johngpvd',
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
          </svg>
        ),
      },
    ],
  }
  
  export default function Footer() {
    return (
      <footer className="bg-blue-bgDark" aria-labelledby="footer-heading">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="max-w-7xl mx-auto py-12 px-4 xl:px-0 lg:py-16 lg:px-8">
          <div className="xl:grid xl:grid-cols-3 xl:gap-8">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 xl:col-span-2">
              <div className="md:grid md:grid-cols-2 md:gap-8">
                <div>
                  <h3 className="text-base font-semibold text-gray-400 tracking-wider uppercase">Meet John</h3>
                  <ul role="list" className="mt-4 space-y-4">
                    {navigation.meetJohn.map((item) => (
                      <li key={item.name}>
                        <a href={item.href} className={item.className ? item.className : "text-lg text-gray-300 hover:text-white"}>
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-12 md:mt-0">
                  <h3 className="text-base font-semibold text-gray-400 tracking-wider uppercase">Media</h3>
                  <ul role="list" className="mt-4 space-y-4">
                    {navigation.media.map((item) => (
                      <li key={item.name}>
                        <a href={item.href} className={item.className ? item.className : "text-lg text-gray-300 hover:text-white"}>
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="md:grid md:grid-cols-2 md:gap-8">
                <div>
                  <h3 className="text-base font-semibold text-gray-400 tracking-wider uppercase">Get Involved</h3>
                  <ul role="list">
                    {navigation.getInvolved.map((item) => (
                      <li key={item.name} className="mt-5">
                        <a href={item.href} className={item.className ? item.className : "text-lg text-gray-300 hover:text-white"}>
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-12 md:mt-0">
                  <h3 className="text-base font-semibold text-gray-400 tracking-wider uppercase">Contact</h3>
                  <ul role="list" className="mt-4 space-y-4">
                    {navigation.contact.map((item) => (
                      <li key={item.name}>
                        <a href={item.href} className={item.className ? item.className : "text-lg text-gray-300 hover:text-white"}>
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="footer-ngp-van-email-signup-form mt-8 xl:mt-0">
              <h3 className="text-base font-semibold text-gray-400 tracking-wider uppercase">
                Subscribe to our newsletter
              </h3>
              <p className="text-lg text-gray-300">
              Sign up for newsletter updates &amp; to join John's grassroots team!
              </p>
              <form
                className="mt-3 text-left text-white"
                method="POST"
                action="#"
                onSubmit={onFormSubmit}
              >
                <input type="hidden" name="SmsSubscribeMobilePhone.Value" id="SmsSubscribeMobilePhone_Value"  value="true" />
                <input type="hidden" name="YesSignMeUpForUpdatesForBinder.Value" id="YesSignMeUpForUpdatesForBinder_Value"  value="true" />
                <input type="hidden" name="SocialNetworkTrackingId.Value" id="SocialNetworkTrackingId_Value" />
                <input type="hidden" name="SocialNetwork.Value" id="SocialNetwork_Value" />
                <div className="grid grid-cols-2 gap-x-4 gap-y-4">
                  <div>
                    <label htmlFor="first-name" className="block text-base font-medium">
                      First Name
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="FirstName"
                        id="first-name"
                        autoComplete="given-name"
                        className="py-3 px-4 block w-full shadow-sm focus:ring-red-500 focus:border-blue-500 border-gray-300 rounded-md text-black"
                        pattern="\D+"
                        required
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="last-name" className="block text-base font-medium">
                      Last Name
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="LastName"
                        id="last-name"
                        autoComplete="family-name"
                        className="py-3 px-4 block w-full shadow-sm focus:ring-red-500 focus:border-blue-500 border-gray-300 rounded-md text-black"
                        pattern="\D+"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-span-2">
                    <label htmlFor="email" className="block text-base font-medium">
                      Email
                    </label>
                    <div className="mt-1">
                      <input
                        id="email"
                        name="EmailAddress"
                        type="email"
                        autoComplete="email"
                        className="py-3 px-4 block w-full shadow-sm focus:ring-red-500 focus:border-blue-500 border-gray-300 rounded-md text-black"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-span-2">
                    <label htmlFor="tel" className="block text-base font-medium">
                      Mobile Phone Number (optional)
                    </label>
                    <div className="mt-1">
                      {/* TODO: validate this following https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/tel.*/ }
                      <input
                        id="tel"
                        name="MobilePhone"
                        type="tel"
                        autoComplete="tel"
                        className="py-3 px-4 block w-full shadow-sm focus:ring-red-500 focus:border-blue-500 border-gray-300 rounded-md text-black"
                      />
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-3 mt-4 gap-x-4">
                  <div className="col-span-1">
                    <label htmlFor="zip-code" className="block text-base font-medium">
                      ZIP Code
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="PostalCode"
                        id="zip-code"
                        autoComplete="zip-code"
                        className="py-3 px-4 block w-full shadow-sm focus:ring-red-500 focus:border-blue-500 border-gray-300 rounded-md text-black"
                        pattern="(\d{5}([\-]\d{4})?)"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-span-2 pt-6">
                    <button
                    type="submit"
                    className="w-full h-full border border-transparent text-lg font-medium rounded-md text-white bg-orange-button shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:mt-0 sm:items-center"
                    >
                      Join Our Team
                    </button>
                  </div>
                </div>
                <div className="mt-4">
                  <p className="text-sm mb-0">By providing your mobile phone number, you consent to receive recurring text messages from John Goncalves for Congress.  Message & Data Rates May Apply.  Text STOP to opt out.</p>
                </div>
              </form>
            </div>
            <div className="footer-ngp-van-email-signup-success text-white mt-8 xl:mt-0 hidden">
              <h3 className="text-base font-semibold text-gray-400 tracking-wider uppercase">
                Subscribe to our newsletter
              </h3>
              <p className="text-lg text-gray-300">
                Thank you for joining our team!
              </p>
              <p className="text-lg text-gray-300">
                Make a donation to help elect John Goncalves! Every dollar fuels our grassroots campaign.
              </p>
              <p className="text-lg text-gray-300">
                Now redirecting you to a donation page...
              </p>
            </div>
            <div className="footer-ngp-van-email-signup-already-subscribed text-white mt-8 xl:mt-0 hidden">
              <h3 className="text-base font-semibold text-gray-400 tracking-wider uppercase">
                Subscribe to our newsletter
              </h3>
              <p className="text-lg text-gray-300">
                Already subscribed&mdash;thank you!
              </p>
            </div>
            <div className="footer-ngp-van-email-signup-error text-red text-white mt-8 xl:mt-0 hidden">
              <h3 className="text-base font-semibold text-gray-400 tracking-wider uppercase">
                Subscribe to our newsletter
              </h3>
              <p className="text-lg text-gray-300">
                Error joining&mdash;please try again.
              </p>
            </div>
          </div>
          <div className="mt-8 border-t border-gray-700 pt-8 md:flex md:items-center md:justify-between">
            <div className="flex space-x-6 md:order-2">
              {navigation.social.map((item) => (
                <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-300" target="_blank">
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              ))}
            </div>
            <div className="mt-8 text-lg text-gray-400 md:mt-0 md:order-1">
              <span className="table sm:inline-block border p-2 mr-2 mb-2">Paid for by John Goncalves for Congress</span>&copy; 2023. All rights reserved. | <a className="text-gray-300 hover:text-white" href="/privacy-policy">Privacy Policy</a>
            </div>
          </div>
        </div>
      </footer>


    )
  }
